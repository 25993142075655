import styled, { css } from 'styled-components';
import EllipsePattern from '../../../images/Ellipse.svg';
import CheckPattern from '../../../images/Union.svg';

import { Link } from 'gatsby';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export const StyledSection = styled.section`
    width: 100%;
    height: 100%;
    padding: 80px 0px;
    background: linear-gradient(199.12deg, #abc6d7 -5.12%, #e4e3e4 92.51%);
    background-size: cover;
    overflow-x: hidden;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 601px) {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background: url(${CheckPattern}) no-repeat top right;
            height: 100%;
            width: 100%;
            z-index: -1;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background: url(${EllipsePattern}) no-repeat bottom left;
            height: 100%;
            width: 100%;
            z-index: -1;
        }
    }

    @media screen and (max-width: 600px) {
        background: #abc6d7;
    }
`;

export const StyledContainer = styled.div`
    max-width: 1280px;
    margin: 0px auto;
    padding: 10px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SectionTitle = styled.h1`
    font-family: Rubik, sans-serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 42.66px;
    text-align: center;
    color: #0c1a68;
`;

export const StyledInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledInfoText = styled.p`
    font-family: Rubik, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    color: #0c1a68;
    margin: 16px auto;

    & span {
        display: block;

        @media screen and (max-width: 600px) {
            display: inline;
        }
    }

    @media screen and (max-width: 600px) {
        margin: 30px auto;
        max-width: 270px;
    }
`;

export const StyledBtnGroupWrapper = styled.div`
    display: flex;
    column-gap: 21px;
`;

export const StyledCtaBtn = styled(Link)`
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    background-color: ${({ bgColor }) => bgColor || '#0c1a68'};
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        transform: scale(1.1);
        transition: all 0.2s;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            background-color: transparent;
            border: 1px solid #0c1a68;
            color: #0c1a68;
        `}
`;

export const StyledTabsContainer = styled.div`
    max-width: 635px;
    padding: 8px;
    margin: 62px auto 32px auto;
    border-radius: 60px;
    background-color: #ffffff80;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 9999;

    @media screen and (max-width: 600px) {
        background-color: #dae5ec;
        border-radius: 0;
        max-width: 100vw;
        width: 100vw;
        margin: 30px 0px;
    }
`;

export const StyledTab = styled.div`
    padding: 10px 40px;
    border-radius: 60px;
    font-family: Rubik, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
    color: ${({ active }) => (active ? '#fff' : '#0c1a68')};
    background-color: ${({ active }) => (active ? '#0c1a68' : 'transparent')};
    transition: background-color 0.8s ease;

    @media screen and (max-width: 600px) {
        background-color: ${({ active }) => (active ? '#0c1a68' : '#dae5ec')};
        border-radius: 40px;
        height: 48px;
        width: 64px;
        padding: 10px;
    }
`;

export const StyledCardsWrapper = styled.div`
    width: 100vw;
    height: content-fit;
    position: relative;
`;

export const StyledCardsContainer = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    margin-bottom: 10px;
    margin-left: 40px;

    @media screen and (max-width: 600px) {
        width: 100vw;
        align-items: center;
        flex-direction: column;
        transform: translateX(0px);
        row-gap: 30px;
        margin-bottom: 30px;
        margin-left: 0px;
        padding-bottom: 30px;
    }
`;

export const StyledChevronButton = styled.button`
    position: absolute;
    top: 50%;
    left: 0;
    height: 49px;
    width: 49px;
    background-color: #fff;
    box-shadow: 0px 0px 14px 0px #00000026;
    border-radius: 100%;
    z-index: 99;
    outline: none;
    border: none;
    margin: auto 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
        transform: scale(1.4);
    }

    ${({ right }) =>
        right &&
        css`
            right: 0;
            left: auto;
        `}

    ${({ hide }) =>
        hide &&
        css`
            display: none;
        `}

    @media screen and (max-width: 600px) {
        display: none;
    }
`;

export const StyledChevronLeft = styled(ChevronLeft)``;

export const StyledChevronRight = styled(ChevronRight)``;

export const StyledCard = styled.div`
    background-color: ${({ active }) => (active ? '#0C1A68' : '#FFFFFF80')};
    padding: 20px 45px 45px 45px;
    border-radius: 20px;
    max-width: 321px;
    min-width: 321px;
    transition: background-color 0.4s ease;

    ${({ active }) =>
        active &&
        css`
            box-shadow: 0px 42px 34px 0px #5243c24b;
        `};

    @media screen and (max-width: 600px) {
        max-width: auto;
        padding: 20px 30px;
    }
`;

export const StyledCardTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding-bottom: 20px;

    & span {
        font-family: 'Rubik', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
        color: ${({ active }) => (active ? '#FFFFFF' : '#231D4F')}; ;
    }
`;

export const StyledCardTitle = styled.h1`
    font-family: Rubik, sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
    text-align: left;
    text-transform: capitalize;
    color: ${({ active }) => (active ? '#FFFFFF' : '#231D4F')};
`;

export const StyledCardBody = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;

export const StyledCardPriceItem = styled.div`
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
    justify-content: flex-start;
`;

export const StyledIconLinkWrapper = styled.div`
    flex: 1 1 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

export const StyledCheckIcon = styled.img``;

export const StyledPriceLink = styled(Link)`
    color: ${({ active }) => (active ? '#ffffff' : '#374151')};
    text-decoration: underline;
    cursor: pointer;
    text-align: left;
`;

export const StyledRequiredTextContainer = styled.div`
    max-width: 585px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-left: 100px;
    margin-top: 50px;

    @media screen and (max-width: 600px) {
        margin-left: 0px;
        margin-top: auto;
        row-gap: 30px;
    }
`;

export const StyledRequiredText = styled.p`
    font-family: Rubik, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #666666;

    & a {
        text-decoration: underline;
        cursor: pointer;
        color: #666666;
    }

    & span {
        font-weight: 700;
        color: #333333;
        margin-right: 6px;
    }
`;

export const StyledSectionFooter = styled.div`
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 600px) {
        margin-top: 33px;
    }
`;

export const StyledFooterText = styled.p`
    font-family: Rubik;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.33px;
    color: #333333;
    margin-top: 16px;

    & a {
        text-decoration: underline;
        cursor: pointer;
        color: #333333;
    }

    @media screen and (max-width: 600px) {
        margin-top: 28px;
    }
`;
