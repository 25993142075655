import React from 'react';
import PropTypes from 'prop-types';

import { StyledCardsContainer } from '../style';
import { pricingData } from '../../../../helper/constant';

import PricingCard from './PricingCard';

const PricingCards = ({ activeCardKey, containerRef }) => {
    return (
        <StyledCardsContainer ref={containerRef}>
            {Object.entries(pricingData).map(([key, value]) => {
                const isCardActive = activeCardKey === key;
                return <PricingCard key={key} isActive={isCardActive} title={key} items={value} />;
            })}
        </StyledCardsContainer>
    );
};

PricingCards.propTypes = {
    activeCardKey: PropTypes.bool,
    containerRef: PropTypes.node,
};

export default PricingCards;
