import React from 'react';
import isMobile from 'is-mobile';
import styled, { css } from 'styled-components';
import { SubHeading } from './styles';

const Card = styled.div`
    width: 260px;
    height: 363px;
    padding: 48px 24px;
    border-radius: 12px;
    background: #417fb1;
    display: grid;
    place-items: center center;
    transition: transform 1s ease-out;
    cursor: pointer;
    margin: 0 auto;

    ${({ middleCard }) =>
        middleCard &&
        css`
            transform: scale(1.2);
            background: #0c1a68;
        `};

    @media screen and (max-width: 768px) {
        transform: scale(1);
        margin-bottom: 45px;
        width: 272px;
        height: 314px;
        padding: 24px;
    }
`;

const LogoWrapper = styled.div`
    width: 72px;
    height: 72px;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: #fff;
    display: grid;
    place-items: center center;
`;

const CardLogo = styled.img`
    width: auto;
    height: 42px;
    filter: invert(55%) sepia(41%) saturate(614%) hue-rotate(160deg) brightness(89%) contrast(91%);

    ${({ middleCard }) =>
        middleCard &&
        css`
            filter: invert(12%) sepia(69%) saturate(2298%) hue-rotate(214deg) brightness(92%)
                contrast(104%);
        `};
`;

const SmallHeading = styled(SubHeading)`
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
    margin-top: 1rem;
`;

const CardDesc = styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #fff;
    text-align: center;
    margin-top: 0.8rem;
`;

const CardButton = styled.button`
    margin-top: 1.4rem;
    border: 1px solid #ffffff80;
    padding: 12px 24px;
    color: #fff;
    border-radius: 6px;
    background: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    cursor: pointer;
`;

const LinkWrap = styled.a`
    text-decoration: none;
    display: inline-block;
    color: #fff;
`;

function IntegrateCard({ card, index, activeCard, handleActiveCard, handleMouseLeave }) {
    const isMiddleCard = !isMobile() ? index === activeCard : false;
    return (
        <Card
            middleCard={isMiddleCard}
            onMouseEnter={() => handleActiveCard(index)}
            onMouseLeave={handleMouseLeave}
        >
            <LogoWrapper>
                <CardLogo
                    src={card.logoSrc}
                    alt={card.altText}
                    middleCard={isMiddleCard}
                    loading="lazy"
                />
            </LogoWrapper>
            <SmallHeading>{card.title}</SmallHeading>
            <CardDesc>{card.description}</CardDesc>
            <LinkWrap href={card.link} target="_blank" rel="noopener noreferrer">
                <CardButton>See details</CardButton>
            </LinkWrap>
        </Card>
    );
}

export default IntegrateCard;
