import React from 'react';
import { StyledRequiredText, StyledRequiredTextContainer } from '../style';
import { Link } from 'gatsby';

const Required = () => {
    return (
        <StyledRequiredTextContainer>
            <StyledRequiredText>
                <span>*</span>Only available if the user is verified using Government ID
                Verification, Knowledge Based Authentication, or SSN Verification.
            </StyledRequiredText>
            <StyledRequiredText>
                <span>**</span>Varies court to court. Refer to the tentative{' '}
                <Link
                    to="https://cdn.authenticating.com/public/website/county_search_fee_2024-04.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    court fee list.
                </Link>
            </StyledRequiredText>
        </StyledRequiredTextContainer>
    );
};

export default Required;
