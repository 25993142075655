import React from "react";
import Authenticating from "../images/logos/authenticate_light.svg";

const AuthenticateLogo = ({ style = {} }) => {
	return (
		<img
			src={Authenticating}
			alt="Authenticate®"
			style={{ width: "auto", height: "12px", ...style }}
			loading="lazy"
		/>
	);
};

export default AuthenticateLogo;
