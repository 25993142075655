import React, { useEffect } from "react";
import styled from "styled-components";
import { Heading, SubHeading } from "./components/styles";

const Container = styled.section`
  padding: 63px 25px;
`;

const DemoBox = styled.div`
  width: 594px;
  height: 600px;
  margin: 30px auto 0;

  @media screen and (max-width: 500px) {
    margin: 24px auto 150px;
    width: 340px;
    height: 520px;
  }
`;

function Demo() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <></>
    // <Container id="demo">
    //   {/* <Heading center secondary>
    //     Get a <span> Demo</span>
    //   </Heading>
    //   <SubHeading center margin="20px" subHeading>
    //     Schedule a time for us show you our APIs in action.
    //   </SubHeading>
    //   <DemoBox>
    //       <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/steve1488?embed=true"></div>
    //   </DemoBox> */}
    // </Container>
  );
}

export default Demo;
