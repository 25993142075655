import React from 'react';
import { StyledBtnGroupWrapper, StyledCtaBtn, StyledInfoContainer, StyledInfoText } from '../style';

const SectionInfo = () => {
    return (
        <StyledInfoContainer>
            <StyledInfoText>
                <span>No set up fee. No minimum monthly commitments.</span>{' '}
                <span>No long term contracts. Pay as you go.</span>
            </StyledInfoText>
            <StyledBtnGroupWrapper>
                <StyledCtaBtn
                    to="https://portal.authenticate.com/register?utm_source=homepage&utm_medium=pricing"
                    target="_blank"
                    rel="noreferrer"
                >
                    Get Started
                </StyledCtaBtn>
                <StyledCtaBtn secondary to="#contact" rel="noreferrer">
                    Contact Us
                </StyledCtaBtn>
            </StyledBtnGroupWrapper>
        </StyledInfoContainer>
    );
};

export default SectionInfo;
