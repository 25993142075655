import React, { useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { InfoOutlined, InfoRounded } from '@mui/icons-material';

const StyledWrapper = styled.div`
    display: inline-block;
    position: relative;
`;

const StyledTip = styled.div`
    max-width: 400px;
    min-width: 200px;
    text-wrap: wrap;
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    color: ${({ mode }) => (mode === 'light' ? '#565656' : '#ffffff')};
    background: ${({ mode }) => (mode === 'light' ? '#ffffff' : '#262626')};
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    border-radius: 8px;
    padding: 12px;
    z-index: 100;

    &::before {
        content: '';
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: 6px;
        margin-left: calc(6px * -1);
    }

    ${({ direction, mode }) =>
        (direction === 'right' &&
            css`
                & {
                    left: calc(100% + 15px);
                    top: 50%;
                    transform: translateX(0) translateY(-50%);
                }

                &::before {
                    left: calc(6px * -1);
                    top: 50%;
                    transform: translateX(0) translateY(-50%);
                    border-right-color: ${mode === 'light' ? '#ffffff' : '#262626'};
                }
            `) ||
        (direction === 'left' &&
            css`
                & {
                    left: auto;
                    right: calc(100% + 15px);
                    top: 50%;
                    transform: translateX(0) translateY(-50%);
                }

                &::before {
                    left: auto;
                    right: calc(6px * -2);
                    top: 50%;
                    transform: translateX(0) translateY(-50%);
                    border-left-color: ${mode === 'light' ? '#ffffff' : '#262626'};
                }
            `)}
`;

const StyledInfoOutlined = styled(InfoOutlined)`
    color: ${({ mode }) => (mode === 'light' ? '#fff' : '#374151')};
`;

const StyledInfoFilled = styled(InfoRounded)`
    color: ${({ mode }) => (mode === 'light' ? '#fff' : '#374151')};
`;

const Tooltip = ({ content, mode }) => {
    const [active, setActive] = useState(false);

    const [direction, setDirection] = useState('right');

    const tooltipRef = useRef();

    const adjustTooltipDirection = () => {
        const tooltipRect = tooltipRef?.current?.getBoundingClientRect();
        const windowWidth = window?.innerWidth;

        let newDirection = direction;

        if (tooltipRect?.left < 0) {
            newDirection = 'right';
        } else if (tooltipRect?.right > windowWidth) {
            newDirection = 'left';
        }

        setDirection(newDirection);
    };

    useLayoutEffect(() => {
        adjustTooltipDirection();
    }, [active, adjustTooltipDirection]);

    const showTip = () => {
        setActive(true);
    };

    const hideTip = () => {
        setActive(false);
    };

    const Icon = active ? StyledInfoFilled : StyledInfoOutlined;
    return (
        <StyledWrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
            <Icon mode={mode} />
            {active && (
                <StyledTip ref={tooltipRef} direction={direction} mode={mode}>
                    {content}
                </StyledTip>
            )}
        </StyledWrapper>
    );
};

Tooltip.propTypes = {
    content: PropTypes.string,
    mode: PropTypes.oneOf(['light', 'dark']),
};

export default Tooltip;
