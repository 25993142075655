import React from 'react';
import PropTypes from 'prop-types';

import CircleCheckWhite from '../../../../images/check-circle-white.svg';
import CircleCheckPink from '../../../../images/check-circle-pink.svg';

import {
    StyledCard,
    StyledCardBody,
    StyledCardPriceItem,
    StyledCardTitle,
    StyledCardTitleWrapper,
    StyledCheckIcon,
    StyledIconLinkWrapper,
    StyledPriceLink,
} from '../style';
import Tooltip from '../../../../components/reusable/Tooltip';

const PricingCard = ({ isActive, title, items }) => {
    return (
        <StyledCard active={isActive}>
            <StyledCardTitleWrapper active={isActive}>
                <StyledCardTitle active={isActive}>{title}</StyledCardTitle>
                {title !== 'free' && <span>per check</span>}
            </StyledCardTitleWrapper>
            <StyledCardBody>
                {items.map((item) => (
                    <StyledCardPriceItem key={item.id}>
                        <StyledCheckIcon
                            src={isActive ? CircleCheckWhite : CircleCheckPink}
                            alt="check-icon"
                        />
                        <StyledIconLinkWrapper>
                            <StyledPriceLink
                                to={item.link}
                                target="_blank"
                                rel="noreferrer"
                                active={isActive}
                            >
                                {item.title}
                            </StyledPriceLink>
                            <Tooltip
                                content={item.toolTipNote}
                                mode={isActive ? 'light' : 'dark'}
                            />
                        </StyledIconLinkWrapper>
                    </StyledCardPriceItem>
                ))}
            </StyledCardBody>
        </StyledCard>
    );
};

PricingCard.propTypes = {
    isActive: PropTypes.bool,
    title: PropTypes.string,
    items: PropTypes.array,
};

export default PricingCard;
