import React, { useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import {
    SectionTitle,
    StyledChevronButton,
    StyledChevronLeft,
    StyledChevronRight,
    StyledContainer,
    StyledSection,
    StyledCardsWrapper,
} from './style';
import SectionInfo from './components/SectionInfo';
import PricingTabs from './components/PricingTabs';
import PricingCards from './components/PricingCards';
import Required from './components/Required';
import Footer from './components/Footer';
import { priceKeyToPositionMap } from '../../../helper/constant';
import { useIsomorphicLayoutEffect } from '../../../hooks/useIsomorphicLayoutEffect';

gsap.registerPlugin(ScrollTrigger);

const Pricing = () => {
    const [activeCardKey, setActiveCardKey] = useState('free');
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);

    const cardsContainerRef = useRef(null);
    const tabsContainerRef = useRef(null);
    const sectionRef = useRef(null);

    const addScrollEffect = (activeCard) => {
        const container = cardsContainerRef?.current;
        if (container) {
            const cardWidth = container.children[0]?.offsetWidth;
            const pos = priceKeyToPositionMap[activeCard];

            if (window.innerWidth > 600) {
                // Calculate the scroll distance to the active card position
                const scrollDistance = pos < 2 ? 30 : pos * cardWidth - 560;

                // Animate the container to scroll to the active card position
                gsap.to(cardsContainerRef?.current, {
                    x: -scrollDistance,
                    duration: 1,
                    ease: 'power2.inOut',
                });
            } else {
                const container = cardsContainerRef?.current;
                const card = container?.children[pos];

                const activeCard = ScrollTrigger.create({
                    trigger: card,
                    start: 'top 120',
                });

                gsap.to(window, {
                    duration: 1,
                    scrollTo: activeCard.start,
                    overwrite: 'auto',
                });
            }
        }
    };

    const onTabSelection = (key) => {
        setActiveCardKey(key);
        addScrollEffect(key);
    };

    useIsomorphicLayoutEffect(() => {
        const tabsContainer = tabsContainerRef?.current;
        const cardsContainer = cardsContainerRef?.current;

        let pin;

        if (window.innerWidth <= 600) {
            if (tabsContainer) {
                //pin tabs to the top on mobile screen
                pin = gsap.to(tabsContainer, {
                    scrollTrigger: {
                        trigger: tabsContainer,
                        start: 'bottom 110',
                        endTrigger: cardsContainer?.children[4],
                        end: 'top ',
                        pin: true,
                    },
                });
            }

            if (cardsContainer) {
                const cards = Array.from(cardsContainer?.children);

                //highlight a card on vertical scroll
                cards?.forEach((card, index) => {
                    gsap.to(card, {
                        scrollTrigger: {
                            id: index,
                            trigger: card,
                            start: 'top 25%',
                            end: 'top',
                            onToggle: ({ isActive }) => {
                                if (isActive) {
                                    const newActiveCardKey =
                                        Object.keys(priceKeyToPositionMap)[index];
                                    setActiveCardKey(newActiveCardKey);
                                }
                            },
                        },
                    });
                });
            }
        }

        // 🧹 Cleanup
        return () => {
            pin?.kill();
        };
    }, []);

    const computeArrowVisibility = (containerRect) => {
        const isRightArrowVisible = Math.round(containerRect.right) > window.innerWidth;
        const isLeftArrowVisible = containerRect.left < 0;
        const isBothArrowsVisible = Math.abs(containerRect.left) > Math.round(containerRect.right);

        return { isLeftArrowVisible, isRightArrowVisible, isBothArrowsVisible };
    };

    useIsomorphicLayoutEffect(() => {
        const container = cardsContainerRef?.current;
        const rect = container.getBoundingClientRect();

        const { isLeftArrowVisible, isRightArrowVisible, isBothArrowsVisible } =
            computeArrowVisibility(rect);

        setShowLeftArrow(isLeftArrowVisible || isBothArrowsVisible);
        setShowRightArrow(isRightArrowVisible || isBothArrowsVisible);
    }, [activeCardKey]);

    const onClickLeftArrow = () => {
        const container = cardsContainerRef?.current;
        const rect = container.getBoundingClientRect();
        const scrollDistance = container?.children[0].offsetWidth;

        const { isLeftArrowVisible, isRightArrowVisible, isBothArrowsVisible } =
            computeArrowVisibility(rect);

        setShowLeftArrow(isLeftArrowVisible || isBothArrowsVisible);
        setShowRightArrow(isRightArrowVisible || isBothArrowsVisible);

        gsap.to(container, {
            x: `-=${scrollDistance * -1}`,
            duration: 1,
            ease: 'power2.inOut',
        });
    };

    const onClickRightArrow = () => {
        const container = cardsContainerRef?.current;
        const rect = container.getBoundingClientRect();
        const scrollDistance = container?.children[0].offsetWidth;

        const { isLeftArrowVisible, isRightArrowVisible, isBothArrowsVisible } =
            computeArrowVisibility(rect);

        setShowLeftArrow(isLeftArrowVisible || isBothArrowsVisible);
        setShowRightArrow(isRightArrowVisible || isBothArrowsVisible);

        gsap.to(container, {
            x: `+=${scrollDistance * -1}`,
            duration: 1,
            ease: 'power2.inOut',
        });
    };

    return (
        <StyledSection id="pricing" ref={sectionRef}>
            <StyledContainer>
                <SectionTitle>Our Pricing</SectionTitle>
                <SectionInfo />
                <PricingTabs
                    activeCardKey={activeCardKey}
                    onTabSelection={onTabSelection}
                    containerRef={tabsContainerRef}
                />
                <StyledCardsWrapper>
                    <StyledChevronButton hide={!showLeftArrow} onClick={onClickLeftArrow}>
                        <StyledChevronLeft />
                    </StyledChevronButton>
                    <StyledChevronButton right hide={!showRightArrow} onClick={onClickRightArrow}>
                        <StyledChevronRight />
                    </StyledChevronButton>
                    <PricingCards activeCardKey={activeCardKey} containerRef={cardsContainerRef} />
                </StyledCardsWrapper>
                <Required />
                <Footer />
            </StyledContainer>
        </StyledSection>
    );
};

export default Pricing;
