import React from 'react';
import PropTypes from 'prop-types';

import { StyledTab, StyledTabsContainer } from '../style';
import { pricingData } from '../../../../helper/constant';

const PricingTabs = ({ activeCardKey, onTabSelection, containerRef }) => {
    return (
        <StyledTabsContainer ref={containerRef}>
            {Object.keys(pricingData).map((item) => (
                <StyledTab
                    key={item}
                    active={item === activeCardKey}
                    onClick={() => onTabSelection(item)}
                >
                    {item}
                </StyledTab>
            ))}
        </StyledTabsContainer>
    );
};

PricingTabs.propTypes = {
    activeCardKey: PropTypes.bool,
    onTabSelection: PropTypes.func,
    containerRef: PropTypes.node,
};

export default PricingTabs;
