import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import './form.css';
import ConfirmationModal from '../../ConfirmationModal';
import Loader from '../../../../sections/Resources/components/Loader';

const Container = styled.div`
    width: 100%;
`;

const Headings = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: relative;
    padding: 62px 20px 24px 20px;

    @media screen and (max-width: 560px) {
        padding: 44px 0 24px 0;
    }
`;

const Title = styled.h1`
    font-size: 36px;
    font-weight: 500;
    line-height: 42.66px;
    color: #1d2939;

    @media screen and (max-width: 560px) {
        font-size: 24px;
        line-height: 14.22px;
    }
`;
const SubTitle = styled.p`
    text-align: center;
    max-width: 414px;
    font-weight: 400;
    color: #212121;
    line-height: 16.59px;

    @media screen and (max-width: 560px) {
        font-size: 12px;
        line-height: 14.22px;
        max-width: 264px;
    }
`;

const FormContainer = styled.div`
    max-width: 100%;
`;

const ContactUsForm = ({ handleOnSubmit, buttonStyle = 'normal' }) => {
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const [isFormLoaded, setIsFormLoaded] = React.useState(true);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/embed/v2.js';
        script.async = true;
        script.onload = () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: 'na1',
                    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
                    formId: process.env.GATSBY_HUBSPOT_CONTACT_FORM_ID,
                    target: '#hubspotForm',
                    onFormReady: ($form) => {
                        setIsFormLoaded(false);
                        const submitBtnDiv = $form[0].querySelector('.hs-submit');
                        if (submitBtnDiv) {
                            if (buttonStyle === 'sticky') {
                                submitBtnDiv.classList.add('button-sticky');
                            } else {
                                submitBtnDiv.classList.add('button-default');
                            }
                        }
                    },
                    onFormSubmitted: () => {
                        setIsFormSubmitted(true);
                    },
                });
            }
        };

        document.body.appendChild(script);
    }, [handleOnSubmit, buttonStyle, setIsFormSubmitted, setIsFormLoaded]);

    useEffect(() => {
        if (isFormSubmitted && handleOnSubmit) {
            setTimeout(() => {
                handleOnSubmit();
            }, 4000);
        }
    }, [isFormSubmitted, handleOnSubmit]);
    return (
        <Container id="contact">
            {isFormSubmitted ? (
                <ConfirmationModal
                    handleClose={handleOnSubmit}
                    message="Thank you for your message. We will get back to you shortly."
                    textAlign="center"
                />
            ) : (
                <>
                    <Headings>
                        <Title>Contact Us</Title>
                        <SubTitle>
                            Submit the form with any questions, and we'll be in touch within 24
                            hours.
                        </SubTitle>
                    </Headings>
                    {isFormLoaded && <Loader />}
                    <FormContainer>
                        <div
                            id="hubspotForm"
                            style={{ display: isFormLoaded ? 'none' : 'flex' }}
                        ></div>
                    </FormContainer>
                </>
            )}
        </Container>
    );
};

ContactUsForm.propTypes = {
    handleOnSubmit: PropTypes.func.isRequired,
    buttonStyle: PropTypes.oneOf(['normal', 'sticky']).isRequired,
};

export default ContactUsForm;
