import React from 'react';

const indicatorStyles = {
    background: '#4694c460',
    width: 9,
    height: 9,
    display: 'inline-block',
    margin: '0 8px',
    borderRadius: '50%',
};

function CarouselIndicator({ onClickHandler, isSelected, index, label }) {
    const selectedStyle = isSelected
        ? {
              ...indicatorStyles,
              background: '#4694c4',
          }
        : indicatorStyles;

    return (
        <li
            style={selectedStyle}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
        />
    );
}

export default CarouselIndicator;
