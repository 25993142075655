import React from 'react';
import styled, { css } from 'styled-components';
import { Heading, SubHeading, AuthSvg } from './components/styles';
//assets
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import persons from '../../images/persons-animated.gif';
import monitored from '../../images/monitored-animated.gif';
import process from '../../images/process-animated.gif';
import laptop from '../../images/laptop-animated.gif';
import { Link } from 'gatsby';

const SolutionsContainer = styled.section`
    background-color: rgba(243, 250, 254, 1);
    width: 100%;
    padding-bottom: 1rem;
`;

const Container = styled.section`
    padding-top: 52px;
    max-width: 1280px;
    margin: 0 auto;

    @media screen and (max-width: 500px) {
        padding-top: 36px;
        margin: 20px auto;
    }
`;

const CardContainer = styled.div`
    margin: 72px 92px;

    @media screen and (max-width: 500px) {
        margin: 30px 20px;
    }
`;

const CardWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: ${(props) => (props.last ? '0' : '106px')};
    place-items: center center;
    gap: 2rem;

    @media screen and (max-width: 500px) {
        margin-bottom: ${(props) => (props.last ? '0' : '48px')};
        grid-template-columns: 1fr;
        text-align: center;
    }
`;

const CardBody = styled.div`
    width: 100%;
    order: ${(props) => (props.reverse ? '1' : 'none')};

    @media screen and (max-width: 500px) {
        order: ${(props) => (props.reverse ? '2' : 'none')};
        width: auto;
    }
`;

const CardImage = styled.div`
    width: auto;
    order: ${(props) => (props.reverse ? '2' : 'none')};

    @media screen and (max-width: 500px) {
        order: ${(props) => (props.reverse ? '1' : 'none')};
    }
`;

const Image = styled.img`
    width: 100%;

    @media screen and (max-width: 500px) {
        height: auto;
        margin: 0 auto;
    }
`;

const SolutionSubHeading = styled(SubHeading)`
    margin-top: 16px;
    @media screen and (max-width: 500px) {
        font-size: 14px;
        margin-top: 12px;

        br {
            display: none;
        }
    }
    a {
        text-decoration: none;
        color: #0066cc;
    }
`;

const SharedCtaStyle = css`
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #4694c4;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-top: 36px;
    gap: 4px;

    @media screen and (max-width: 500px) {
        margin-top: 24px;
        justify-content: center;
    }
`;
const InternalLink = styled(Link)`
    ${SharedCtaStyle}
`;

const ExternalLink = styled.a`
    ${SharedCtaStyle}
`;

const ArrowIcon = styled(ChevronRightIcon)`
    width: 16px;
    height: 16px;
    transition: transform 0.4s ease-in-out;

    ${InternalLink}:hover &,
    ${ExternalLink}:hover & {
        transform: translateX(4px);
    }
`;

function Solutions() {
    return (
        <SolutionsContainer>
            <Container id="solution">
                <Heading center secondary style={{ color: '#252963' }}>
                    Our Solutions
                </Heading>
                <CardContainer>
                    <CardWrapper>
                        <CardImage>
                            <Image
                                src={persons}
                                alt="api calls dashboard"
                                width="416px"
                                height="391px"
                            />
                        </CardImage>
                        <CardBody>
                            <Heading secondary>
                                Prevent Fake Accounts <br /> Stop Fraud Worldwide
                            </Heading>
                            <SolutionSubHeading>
                                Verify bank accounts, photo ID, social security numbers, <br />
                                email, phone and more to prove users are real and stop
                                <br /> fraud.
                            </SolutionSubHeading>
                            <InternalLink to="/solutions/identity-fraud-prevention">
                                See How
                                <ArrowIcon />
                            </InternalLink>
                        </CardBody>
                    </CardWrapper>
                    <CardWrapper>
                        <CardImage reverse>
                            <Image
                                src={monitored}
                                alt="Monitoring Image"
                                width="452px"
                                height="287px"
                            />
                        </CardImage>
                        <CardBody reverse>
                            <Heading secondary>
                                Continuous Monitoring <br /> Instant Threat Alerts
                            </Heading>
                            <SolutionSubHeading>
                                Daily population monitoring for arrests, warrants or <br />
                                imprisonments, driving infractions, moving violations, <br />
                                suspensions, revocations & more.
                            </SolutionSubHeading>
                            <InternalLink href="/products/tcm">
                                See Details
                                <ArrowIcon />
                            </InternalLink>
                        </CardBody>
                    </CardWrapper>
                    <CardWrapper>
                        <CardImage>
                            <Image
                                src={process}
                                alt="Validate Image"
                                width="392px"
                                height="371px"
                            />
                        </CardImage>
                        <CardBody>
                            <Heading secondary>
                                <AuthSvg>
                                    <svg
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0.908203 26.6989H6.52969L8.35118 21.2345L4.26854 17.5915L0.971014 26.6989H0.908203ZM9.67019 16.8064L12.8107 7.38488C13.69 9.87634 14.5484 12.3783 15.3859 14.8907L19.7512 11.97L15.6371 0.632812H10.3611L4.92804 15.5502L9.67019 16.8064ZM16.9561 19.6642L20.9132 15.2361L25.09 26.6989H19.4057L16.9561 19.6642Z"
                                            fill="#212121"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M4.39258 16.4635L12.0868 18.3478L24.0835 10.4023L12.3066 23.5924L4.39258 16.4635Z"
                                            fill="#212121"
                                        />
                                    </svg>
                                </AuthSvg>
                                uthenticate<sup>®</sup> Education,
                                <br />
                                Credentials & Experience
                            </Heading>
                            <SolutionSubHeading>
                                <AuthSvg>
                                    <svg
                                        width="24"
                                        height="26"
                                        viewBox="0 0 24 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3.40381 15.6862L10.9317 17.5424L22.7318 9.7038L11.135 22.9058L3.40381 15.6862Z"
                                            fill="#212121"
                                        />
                                        <path
                                            d="M7.26807 15.5884L10.2153 7.69674C10.8104 6.0533 11.2706 4.39792 11.5969 2.73059C11.9931 4.13808 12.5999 5.93458 13.4159 8.12234L15.3682 13.3745L18.1549 11.5234L13.5043 0H9.84857L4.22803 14.8387L7.26807 15.5884V15.5884Z"
                                            fill="#212121"
                                        />
                                        <path
                                            d="M3.38691 17.0586L0 26H3.60342L5.94679 19.4487L3.38691 17.0586Z"
                                            fill="#212121"
                                        />
                                        <path
                                            d="M19.5181 14.8984L17.0039 17.7604L20.1338 26H24.0001L19.5181 14.8984Z"
                                            fill="#212121"
                                        />
                                    </svg>
                                </AuthSvg>
                                uthenticate<sup>®</sup> employment, education & professional <br />
                                licenses through authoritative database checks & <br />
                                verification at the source.
                            </SolutionSubHeading>
                            <InternalLink to="/products/background-check">
                                Learn How
                                <ArrowIcon />
                            </InternalLink>
                        </CardBody>
                    </CardWrapper>
                    <CardWrapper last>
                        <CardImage reverse>
                            <Image
                                src={laptop}
                                alt="Laptop code Image"
                                width="467px"
                                height="233px"
                            />
                        </CardImage>
                        <CardBody reverse>
                            <Heading secondary>
                                Easy-to-Use <br />
                                Developer Friendly
                            </Heading>
                            <SolutionSubHeading>
                                Our{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://verify.authenticating.com/onboarding"
                                >
                                    hosted UI
                                </a>
                                ,{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://docs.authenticate.com/"
                                >
                                    {' '}
                                    APIs & SDKs{' '}
                                </a>
                                are a joy to work with. Our <br />{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://portal.authenticate.com/"
                                >
                                    developer portal
                                </a>
                                ,{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://docs.authenticate.com/reference/post_mock-user-create-1"
                                >
                                    mock APIs
                                </a>{' '}
                                &{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://docs.authenticate.com/reference/post_company-webhook-1"
                                >
                                    webhooks
                                </a>{' '}
                                are too. <br />
                                Customize our{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://docs.authenticate.com/docs/medalliontm"
                                >
                                    {' '}
                                    iFrame{' '}
                                </a>{' '}
                                to match your brand style.
                            </SolutionSubHeading>
                            <ExternalLink
                                href="https://docs.authenticate.com/docs/getting-started"
                                target="_blank"
                                rel="noreferrer"
                            >
                                How it Works
                                <ArrowIcon />
                            </ExternalLink>
                        </CardBody>
                    </CardWrapper>
                </CardContainer>
            </Container>
        </SolutionsContainer>
    );
}

export default Solutions;
