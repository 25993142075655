import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Heading } from './components/styles';
import { integrationCards } from '../../helper/constant';
import isMobile from 'is-mobile';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { Carousel } from 'react-responsive-carousel';
import CarouselIndicator from './components/CarouselIndicator';
import IntegrateCard from './components/IntegrateCard';

const Container = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 72px 92px;
    overflow-x: hidden;

    @media screen and (max-width: 912px) {
        padding: 54px 20px 28px;
    }
`;

const IntegrateHeading = styled(Heading)`
    color: #252963;

    @media screen and (max-width: 768px) {
        margin-bottom: 54px;
    }
`;

const SharedStyle = css`
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    align-items: center;
    justify-items: center;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        margin: 0 auto;
    }
`;

const CardWrapper = styled.div`
    ${SharedStyle}
`;

const MobileCardWrapper = styled(Carousel)`
    ${SharedStyle}
`;

function Integrate() {
    const [activeCard, setActiveCard] = useState(0);
    const anime = useRef(null);

    const handleActiveNumber = () => {
        setActiveCard((prevNumber) => (prevNumber + 1) % 3);
    };

    const loopInterval = () => {
        let intervalID = setInterval(() => {
            // Increment the active card number and reset to 0 if it reaches 3
            handleActiveNumber();
        }, 5000);

        return intervalID;
    };

    function startAnimation() {
        if (anime.current) {
            clearInterval(anime.current);
        }

        anime.current = loopInterval();
    }

    function stopAnimation() {
        if (anime.current) {
            clearInterval(anime.current);
            anime.current = null;
        }
    }

    useEffect(() => {
        startAnimation();

        return () => stopAnimation();
    }, []);

    const handleActiveCard = (index) => {
        setActiveCard(index);
        stopAnimation();
    };

    const handleMouseLeave = () => {
        startAnimation();
    };

    const carouselSettings = {
        showArrows: false,
        showThumbs: false,
        showStatus: false,
        infiniteLoop: true,
        autoPlay: true,
        interval: '5000',
        stopOnHover: false,
        renderIndicator: (onClickHandler, isSelected, index, label) => (
            <CarouselIndicator
                onClickHandler={onClickHandler}
                isSelected={isSelected}
                index={index}
                label={label}
            />
        ),
    };

    return (
        <Container>
            <IntegrateHeading secondary center>
                Integrate in Minutes
            </IntegrateHeading>
            {!isMobile() ? (
                <CardWrapper>
                    {integrationCards.map((card, index) => {
                        return (
                            <IntegrateCard
                                key={`carousel-card-${card.id}`}
                                card={card}
                                index={index}
                                activeCard={activeCard}
                                handleActiveCard={handleActiveCard}
                                handleMouseLeave={handleMouseLeave}
                            />
                        );
                    })}
                </CardWrapper>
            ) : (
                <MobileCardWrapper {...carouselSettings}>
                    {integrationCards.map((card, index) => {
                        return (
                            <IntegrateCard
                                key={`mobile-carousel-card-${card.id}`}
                                card={card}
                                index={index}
                                activeCard={activeCard}
                                handleActiveCard={handleActiveCard}
                                handleMouseLeave={handleMouseLeave}
                            />
                        );
                    })}
                </MobileCardWrapper>
            )}
        </Container>
    );
}

export default Integrate;
