import React from "react";
import MedallionSVG from "../images/Medallion_Logo_White.svg";

const MetaVerifyLogo = ({ style = {} }) => {
	return (
		<img src={MedallionSVG} alt="Medallion™" style={style} loading="lazy" />
	);
};

export default MetaVerifyLogo;
